import { authGet, authPost, awaitRequestBlob, awaitRequestJson } from '@/api/helpers'
import {
  AppIdParam,
  AppRank,
  AppRelationshipsResponse,
  AppResponse,
  SuccessResponse,
  Version,
  VersionItem,
} from '@/types'

export function getAppByRank(appRank: AppRank) {
  return awaitRequestJson<AppResponse>(authGet('app', {}, appRank))
}

export function getAppById(params: AppIdParam, appRank: AppRank) {
  return awaitRequestJson<AppResponse>(authGet('app', params, appRank))
}

export function getAppRelationships(params: AppIdParam) {
  return awaitRequestJson<AppRelationshipsResponse>(authGet('app/relationships', params))
}

export function getVersionList(params: AppIdParam) {
  return awaitRequestJson<VersionItem[]>(authGet('versions', params))
}

export function postChangeCurrentVersion(newVersionId: Version['id'], params: AppIdParam) {
  return awaitRequestJson<SuccessResponse>(
    authPost({
      path: 'app/current-version',
      body: { newVersionId },
      params,
    }),
  )
}

export function getVersionStatus(appRank: AppRank) {
  return awaitRequestJson<Version['status']>(authGet('version/status', {}, appRank))
}

export function postVersionStatus(status: Version['status']) {
  return awaitRequestJson<SuccessResponse>(authPost({ path: 'version/status', body: { status } }))
}

export function getDataDownload(params: { fields: string[] }, appRank: AppRank) {
  // Todo: confirm response type
  return awaitRequestBlob(authGet('version/download', params, appRank))
}

export function getExport(params: {}) {
  return awaitRequestBlob(authGet('version/export', params))
}
